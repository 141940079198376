import Script from 'next/script';

export default function Scripts(props) {
  return (
    <>
      {/*<Script*/}
      {/*  strategy="lazyOnload"*/}
      {/*  crossOrigin="anonymous"*/}
      {/*  dangerouslySetInnerHTML={{*/}
      {/*    __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':*/}
      {/*      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],*/}
      {/*      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=*/}
      {/*      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);*/}
      {/*    })(window,document,'script','dataLayer','GTM-WMT3GV5');*/}
      {/*  `,*/}
      {/*  }}*/}
      {/*/>*/}

      {/*<noscript*/}
      {/*  dangerouslySetInnerHTML={{*/}
      {/*    __html: `<iframe loading="lazy" src="https://www.googletagmanager.com/ns.html?id=GTM-WMT3GV5" height="0" width="0" style="display: none; visibility: hidden;" />`,*/}
      {/*  }}*/}
      {/*/>*/}

      <Script
        src="https://config.confirmic.com/config.js?id=prj:8e503d1a-a2c9-41f5-8da4-3cfeea42f159"
        crossOrigin="anonymous"
        charSet="utf-8"
        strategy="lazyOnload"
      />
      <Script
        src="https://consent-manager.confirmic.com/embed.js"
        crossOrigin="anonymous"
        charSet="utf-8"
        strategy="lazyOnload"
      />
    </>
  );
}
